.businessTrainings {
  display: flex;
  align-content: flex-start;
  justify-content: space-between;
  gap: 30px;
  flex-wrap: wrap;

  height: 100vh;
  width: 100%;
  padding: 30px 0;
  margin: 0;

  &.mute {
  }

  @media (max-width: 768px) {
    height: max-content;
  }

  @media (max-width: 600px) {
    padding: 15px;
  }

}