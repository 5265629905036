.lifeManagement {

  display: flex;
  justify-content: center;
  gap: 15px;
  flex-wrap: wrap;

  min-height: 100vh;
  padding: 30px 0 0 0;

  .block {
    flex: 1;
    display: flex;

    @media (max-width: 768px) {
      flex: 100%;
    }
  }

  .containerTitle {
    width: 100%;
    display: flex;
    align-items: self-start;
    gap: 5px;

    font-size: x-large;
    color: black;
    text-transform: uppercase;
  }

  .contentBox {
    position: relative;
    z-index: 2;

    width: 100%;
    margin: 0 auto;

    min-height: 50px;
    padding: 15px;

    background: rgb(255 255 255 / 48%);
    box-shadow: 0 8px 32px 0 rgb(62 62 62 / 37%);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 12px;

    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .process {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    overflow: hidden;

    gap: 15px;

    border-radius: 20px;

    .topLine {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      width: 100%;

      padding: 15px;

      background: rgb(232 232 232 / 40%);
      box-shadow: 0 8px 32px 0 rgb(62 62 62 / 37%);
      backdrop-filter: blur(4px);
      border-radius: 12px;

      .item {
        width: 32%;
        position: relative;

        img {
          width: 30%;
          position: absolute;
          margin: auto;
          right: 0;
          top: 30px;
        }
      }
    }

    .file {
      display: flex;
      align-items: center;
      justify-content: center;

      flex: 1;
      aspect-ratio: 16 / 4;

      margin: 10px 0;

      border-radius: 12px;
      border: 2px transparent solid;
      background: #28647e1f;

      font-size: 50px;
      color: cornflowerblue;

      &:hover {
        position: relative;
        color: white;
        cursor: pointer;
        border: 2px #8fa9b5 solid;
        background: #4682b454;

        &::after {
          content: 'Download';
          position: absolute;
          bottom: 0;

          font-size: 15px;
          text-transform: uppercase;
        }

        svg {
          position: relative;
          margin-top: 15px;

          transition: all 0.5s;
        }
      }
    }

    .hint {
      display: block !important;
      color: #888e91;
      text-transform: uppercase;
      font-weight: lighter !important;

      &::before {
        content: '* ';
        color: red;
      }
    }
  }
}