.gallery {
  min-height: 100vh;
  padding: 30px 0;

  h1 {
    width: 100%;
    margin: 0 0 50px 0;

    font-size: 50px;
    text-align: center;
    text-transform: uppercase;
    color: dimgrey;
    text-shadow: 0 2px 4px rgb(71 97 206 / 36%);
  }
}
