.consultingPage {
  display: flex;
  align-content: flex-start;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  height: 100vh;
  padding: 30px 0;

  &.mute {
    height: 0;
  }

  i {
    z-index: 2;
    color: white;
    font-size: 75px;
    transform: rotate(90deg);

    @media (max-width: 768px) {
      margin: auto;
    }

    @media (max-width: 600px) {
      padding: 15px;

      p {
        font-size: small !important;
      }
    }
  }
}