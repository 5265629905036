.transformation {
  display: flex;
  justify-content: center;
  gap: 15px;
  flex-wrap: wrap;

  height: max-content;
  min-height: 100vh;
  padding: 30px 0 0 0;

  .block {
    flex: 1;
    display: flex;

    p {
      flex: 1;
      font-size: 16px;
    }

    @media (max-width: 768px) {
      flex: 100%;
    }
  }

  .process {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    overflow: hidden;

    gap: 15px;
    padding: 10px 0 0 0;

    border-radius: 20px;

    .topLine {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      position: relative;
      width: 100%;

      padding: 15px;

      background: rgb(232 232 232 / 40%);
      box-shadow: 0 8px 32px 0 rgb(62 62 62 / 37%);
      backdrop-filter: blur(4px);
      border-radius: 12px;

      .item {
        position: relative;

        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 15px;

        width: max-content;

        h1 {
          border-radius: 50%;
          background: white;
          height: 150px;
          width: 150px;
        }

        img {
          width: 90%;
        }

        p {
          width: 100%;
          color: #878787;

          @media (max-width: 768px) {
            height: 100px;
            font-size: 12px;
            word-break: break-word;
          }
        }
      }
    }

    .bottomLine {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 15px;
      width: 100%;
    }
  }

  .userFeedback {
    width: 100%;
    padding: 0 45px 50px 45px;

    .card {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;

      z-index: 2;

      width: 25%;
      height: max-content;
      padding: 15px;

      border-radius: 12px;
      border: 2px transparent solid;
      background: rgb(232 232 232 / 38%);
      box-shadow: 0 8px 10px rgb(62 62 62 / 37%);
      backdrop-filter: blur(4px);
      -webkit-backdrop-filter: blur(4px);

      cursor: pointer;


      @media (max-width: 768px) {
        width: 100%;
        gap: 15px;
      }

      img {
        width: 50%;

        @media (max-width: 768px) {
          flex: 1;
        }
      }

      .position {
        min-height: 50px;
      }

      .description {
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        @media (max-width: 768px) {
          flex: 1;
        }
      }

      .dropdownBox {
        position: relative;
        z-index: 2;

        width: 100%;

        &[open] {
          .dropdownTitle {
            margin-top: -45px;

            p {
              display: none;
            }
          }

          p {
            margin-top: 20px;
            padding-right: 5px;
            max-height: 300px;
            overflow-y: scroll;

            &::-webkit-scrollbar {
              width: 2px;
            }
          }
        }

        &[open] > .dropdownTitle::after {
          content: "-";
        }

        .dropdownTitle {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-between;

          width: 100%;
          min-height: 30px;

          &::marker {
            display: none;
            content: '';
          }

          &::after {
            content: "+";

            position: absolute;
            right: 0;
            z-index: 5;

            display: flex;
            align-items: center;
            justify-content: center;

            width: 30px;
            height: 30px;

            background: white;
            border-radius: 50%;

            color: black;
            font-size: 1.5em;
            font-weight: normal;
            box-shadow: 0 3px 6px rgba(0, 0, 0, .275);
          }
        }
      }
    }
  }

  iframe {
    flex: 1;
    border: none;
    border-radius: 12px;
    height: 100%;
    margin: 0;

    min-height: 300px;

    .ytp-impression-link {
      display: none;
      visibility: hidden;
    }
  }
}