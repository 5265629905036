.container {
  display: flex;
  width: 100%;
  height: 400px;

  margin: 50px 0;

  border-radius: 20px;
  box-shadow: #a3b1c6d9 9px 9px 16px, #a3b1c638 -9px -9px 16px;
  color: dimgray;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    height: 700px;
  }

  .topLine {
    border-radius: 20px 0 0 20px;
    display: flex;
    width: 65%;
    background: linear-gradient(to right, white, #ff8c21);
    padding: 15px;

    @media (max-width: 768px) {
      width: 100%;
    }

    .leftContent {
      width: 100%;

      @media (max-width: 768px) {
        width: 100%;
      }

      h1 {
        color: #ff962e;
        text-transform: uppercase;
      }
    }
  }

  .whyWeSlider {
    height: 100%;
    width: 35%;

    @media (max-width: 768px) {
      height: 50%;
      width: 100%;
    }
  }
}