.container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;

  padding: 15px;

  border-radius: 20px;
  background: linear-gradient(to right, white, #ff8c21);
  box-shadow: #a3b1c6d9 9px 9px 16px, #a3b1c638 -9px -9px 16px;

  .cardTitle {
    margin: 15px 0;
    text-transform: uppercase;
    color: #ff962e;
  }

  .topLine {
    display: flex;
    position: relative;
    width: 100%;

    padding: 15px;

    background: rgb(232 232 232 / 40%);
    box-shadow: 0 8px 32px 0 rgb(62 62 62 / 37%);
    backdrop-filter: blur(4px);
    border-radius: 12px;

    .item {
      width: 20%;
      position: relative;

      img {
        width: 50%;
        position: absolute;
        margin: auto;
        right: 0;
        top: 25%;
      }

      h1 {
        width: 50%;
        font-size: 60px;
        font-weight: bold;
        text-align: center;
        color: #ff962e;
      }

      p {
        width: 50%;
        font-size: 18px;
        text-align: center;
        color: #878787;
      }

      @media (max-width: 768px) {
        h1 {
          font-size: 30px !important;
        }
        P {
          font-size: 9px !important;
        }
      }
    }
  }

  .botLine {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .item {
      width: 100%;
      display: flex;

      p {
        width: 50%;
        font-size: 18px;
        text-align: center;
        color: #878787;
      }
    }
  }
}