.noPage {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;

  .pageName {
    color: #ff962e;
  }
}
