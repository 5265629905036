.travel {
  display: flex;
  justify-content: center;
  gap: 15px;
  flex-wrap: wrap;

  min-height: 100vh;
  padding: 30px 0 0 0;

  img {
    width: 100%;
    border-radius: 12px;
  }

  .videoBox {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;

    width: 100%;

    border-radius: 12px;
    overflow: hidden;

    .leftContent {
      height: 300px;
      flex: 1.5;
      display: flex;
      align-items: center;

      iframe {
        border: none;
        border-radius: 12px;
        width: 100%;
        height: 100%;
        margin: 0;

        .ytp-impression-link {
          display: none;
          visibility: hidden;
        }
      }

      @media (max-width: 768px) {
        width: 100%;
        margin: 0;
      }
    }

    .rightContent {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      align-content: flex-start;
      flex-wrap: wrap;

      flex: 0.5;

      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;

      span {
        width: 100%;
      }

      @media (max-width: 768px) {
        width: 100%;
        margin: 0;
      }
    }

  }
}