.swiper {
  position: relative;
  width: 100%;
  height: 350px;
  margin: 50px 0;

  border-radius: 20px;
  box-shadow: #a3b1c6d9 9px 9px 16px, #a3b1c638 -9px -9px 16px;

  @media (max-width: 768px) {
    height: 500px;
  }
}

.videoBox {
  display: flex;
  flex-wrap: wrap;

  height: 100%;
  width: 100%;

  border-radius: 20px;
  overflow: hidden;

  .leftContent {
    width: 40%;
    display: flex;
    align-items: center;
    margin-right: 50px;

    @media (max-width: 768px) {
      width: 100%;
      margin: 0;
    }

    iframe {
      border: none;
      width: 100%;
      height: 100%;

      .ytp-impression-link {
        display: none;
        visibility: hidden;
      }
    }

  }

  .rightContent {
    display: flex;
    justify-content: center;
    width: 50%;
    flex-wrap: wrap;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin-bottom: 50px;
    margin-top: 50px;

    @media (max-width: 768px) {
      width: 100%;
      margin: 0;
      padding: 10px;
    }

    h1 {
      width: 100%;
      color: #ff8c21;
    }

    span {
      width: 100%;
    }
  }
}