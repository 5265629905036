.timeManagement {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  gap: 15px;

  height: 100%;
  max-height: 100%;
  width: 100%;


  .targets {
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;

    gap: 10px;

    width: 20%;
    aspect-ratio: 1 / 1;

    background: #8c91914f;
    border-radius: 50%;

    text-align: center;


    @media (max-width: 425px) {
      width: 100%;
      aspect-ratio: 10 / 1;
      padding: 10px;
      border-radius: 20px;
    }

    svg {
      width: 100%;
      font-size: 4rem;
      color: steelblue;

      @media (max-width: 425px) {
        width: 10%;
      }
    }
  }

  .topContent, .middleContent, .bottomContent {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 15px;
    z-index: 2;
  }

  .botContent {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 15px;
    z-index: 2;

    .item {
      display: flex;
      justify-content: center;
      align-content: flex-start;
      flex-wrap: wrap;

      width: 30%;
      padding: 10px;

      background: rgb(232 232 232 / 38%);
      backdrop-filter: blur(4px);
      -webkit-backdrop-filter: blur(4px);
      border-radius: 12px;

      @media (max-width: 425px) {
        width: 100%;
      }
    }
  }
}