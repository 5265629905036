.profit {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  width: 100%;

  background: #ff8c21;
  border-radius: 20px;

  padding: 15px;
  box-shadow: #a3b1c6d9 9px 9px 16px, #a3b1c638 -9px -9px 16px;
  color: white;


  .head {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .content {
    margin: 50px 0;
    width: 80%;
    text-align: left;
  }
}
