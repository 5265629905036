.selfDevelopment {
  display: flex;
  flex-wrap: wrap;

  height: 100%;
  max-height: 100%;
  width: 100%;


  .topContent, .blockOne, .numberRange {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 15px;
    z-index: 2;

    .item {
      display: flex;
      justify-content: flex-start;
      align-content: flex-start;
      flex-wrap: wrap;

      flex: 1;
      padding: 10px;

      background: rgb(232 232 232 / 38%);
      backdrop-filter: blur(4px);
      -webkit-backdrop-filter: blur(4px);
      border-radius: 12px;

      @media (max-width: 425px) {
        width: 100%;
        flex: none;
      }
    }
  }

  .topContent, .blockOne, .blockTwo {
    margin: 10px 0 0 0;

    p {
      display: flex;
      gap: 15px;
      width: 100%;

      font-weight: 600;
      font-size: medium;
      white-space: pre-line;
    }

    .bold {
      text-transform: uppercase;
      font-weight: bold;
    }

    .normal {
      text-align: left;
    }
  }


  iframe {
    flex: 1;
    border: none;
    border-radius: 12px;
    height: 100%;
    margin: 0;

    min-height: 300px;

    .ytp-impression-link {
      display: none;
      visibility: hidden;
    }
  }

}