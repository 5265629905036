.individual {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;

  z-index: 2;
  margin: 0 auto;

  height: 100%;
  max-height: 100%;
  width: 97%;

  .blockTwo {
    display: flex;
    justify-content: space-between;
    text-align: center;
    flex-wrap: wrap;
    z-index: 2;

    column-gap: 30px;

    width: 100%;

    border-radius: 20px;


    :nth-child(2) {
      padding-left: 100px;
      color: dimgrey;
    }

    :nth-child(3) {
      transform: rotate(360deg);
      color: dimgrey;
    }

    :nth-child(4) {
      padding-right: 100px;
      color: dimgrey;
    }

    svg {
      width: 30%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      position: relative;
      height: 60px;
      font-size: 3rem;
      text-align: center;
      line-height: 60px;
      z-index: 3;
      color: #ff962e;
      margin-bottom: 30px;

      @media (max-width: 768px) {
        display: none;
      }
    }

    .reasons {
      min-width: 300px;
      text-align: left;
    }
  }

  .blockThree {
    z-index: 2;

    display: flex;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
    gap: 15px;

    width: 100%;
    padding: 15px;

    border-radius: 20px;
    border: 2px #ff962e solid;

    .dropdownBox {
      position: relative;
      z-index: 2;

      width: 100%;
      margin: 0 auto;

      min-height: 50px;
      padding: 15px;

      background: rgb(121 187 225 / 6%);
      box-shadow: 0 8px 32px 0 rgb(62 62 62 / 37%);
      backdrop-filter: blur(4px);
      -webkit-backdrop-filter: blur(4px);
      border-radius: 12px;

      &[open] > .dropdownTitle::after {
        content: "-";
      }


      .dropdownTitle {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        min-height: 30px;

        .dropdownMassage {
          width: 90%;
          padding: 15px 0;
          text-shadow: 0 2px 4px rgb(71 97 206 / 36%);
        }

        &:after {
          content: "+";
          display: flex;
          align-items: center;
          justify-content: center;

          width: 30px;
          height: 30px;

          background: white;
          border-radius: 50%;

          color: black;
          font-size: 1.5em;
          font-weight: normal;
          box-shadow: 0 3px 6px rgba(0, 0, 0, .275);
        }
      }
    }
  }

  .blockFour {
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    width: 100%;

    .item {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      gap: 20px;

      width: 100%;
      min-height: 100px;

      @media (max-width: 768px) {
        flex: 100%;
        border: none;
      }
    }

    .container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      width: 75%;
      margin: auto;

      border-radius: 12px;
      border-top: 3px solid #ff962e;
      border-right: 3px solid #ff962e;
      border-left: 3px solid #ff962e;

      .circleHeaders {
        display: flex;
        justify-content: space-between;

        width: 100%;
        padding: 20px;
      }

      //& :nth-of-type(2) {
      //  margin-left: -15%;
      //}
      //& :nth-of-type(4) {
      //  margin-right: -15%;
      //}

      .circleBox {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        width: 30%;
        margin-bottom: -3%;

        cursor: pointer;

        img {
          width: 100%;
          aspect-ratio: 1 / 1;
          transition: all 1s;
        }

        &:hover {
          img {
            transform: rotate(60deg);
          }
        }

        h2 {
          text-align: center;
          margin: 0;

          @media (max-width: 425px) {
            width: auto !important;
            font-size: xx-small !important;
          }
        }
      }
    }
  }

}