.scrollTop {
  position: fixed;
  z-index: 5;
  bottom: 50px;
  right: 50px;

  display: flex;
  justify-content: center;
  align-items: flex-start;

  height: 70px;
  width: 40px;

  border: 4px solid #ff8c21;
  border-radius: 30px;
  box-shadow: 0 3px 3px 0 rgb(0 0 0 / 5%), 0 5px 15px 0 rgb(0 0 0 / 5%);

  color: #ff8c21;
  font-size: 30px;
  font-weight: bold;


  &:hover {
    color: white;
    cursor: pointer;
    border: 4px #8fa9b5 solid;
    background: #4682b454;
  }
}