.dreamTeam {
  display: flex;
  align-content: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 15px;

  min-height: 100vh;
  padding: 30px 0 0 0;

  @media (max-width: 768px) {
    padding: 15px;
    height: max-content;
  }

  .card {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    z-index: 2;

    width: 25%;
    height: max-content;
    padding: 15px;

    background: rgb(232 232 232 / 38%);
    box-shadow: 0 8px 32px 0 rgb(62 62 62 / 37%);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 12px;

    @media (max-width: 768px) {
      width: 100%;
      gap: 15px;
    }

    img {
      width: 53%;

      @media (max-width: 768px) {
        flex: 1;
      }
    }

    .description {
      width: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      @media (max-width: 768px) {
        flex: 1;
      }
    }

    .social {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 50px;

      .socialLink {
        display: flex;
        align-items: center;
        width: 50px;

        padding: 10px;
        color: white;
        background: #4682b454;
        border-radius: 12px;
        box-shadow: 0 3px 3px 0 rgb(0 0 0 / 5%), 0 5px 15px 0 rgb(0 0 0 / 5%);

        &:hover {
          background: #ff962e;
        }

        .faLogo {
          font-size: 30px;
          text-align: center;
        }
      }
    }
  }
}