.management {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 50px;

  z-index: 2;
  margin: 0 auto;

  height: 100%;
  max-height: 100%;
  width: 97%;

}