.materialBox {
  gap: 15px;

  padding: 15px;
  margin: 15px 0;

  border-radius: 12px;

  background: rgba(255, 255, 255, 0.48);
  box-shadow: 0 8px 32px 0 rgb(62 62 62 / 37%);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}