.file {
  position: relative;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;

  flex: 1;

  border-radius: 12px;
  border: 2px transparent solid;
  background: #28647e1f;


  color: darkorange;



  @media (max-width: 768px) {
    aspect-ratio: 16 / 10;
    font-size: 20px;
  }

  &:hover {
    position: relative;
    color: white;
    cursor: pointer;
    border: 2px #8fa9b5 solid;
    background: #4682b454;

    &::after {
      content: 'Download';
      position: absolute;
      top: 0;

      font-size: 15px;
      text-transform: uppercase;

      @media (max-width: 768px) {
        font-size: 10px;
      }
    }

    svg {
      position: relative;
      margin-top: 15px;
      transition: all 0.5s;

      @media (max-width: 768px) {
        margin-top: 5px;
      }
    }
  }

  svg {
    margin-top: 10px;
    font-size: 50px;
  }

  .bounce {
    animation: bounce 0.6s infinite alternate;
    -webkit-animation: bounce 0.6s infinite alternate;
  }

  @keyframes bounce {
    from {
      transform: translateY(10px);
    }
    to {
      transform: translateY(-15px);
    }
  }

  @-webkit-keyframes bounce {
    from {
      transform: translateY(0px);
    }
    to {
      transform: translateY(-15px);
    }
  }

  .hintText {
    position: absolute;
    bottom: 0;
    text-align: center;
  }
}