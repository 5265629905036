.businessTrainings {
  display: flex;
  align-content: flex-start;
  justify-content: space-between;
  gap: 30px;
  flex-wrap: wrap;

  min-height: 100vh;
  width: 100%;
  padding: 30px 0;
  margin: 0;

  &.mute {
    min-height: 0 !important;
    height: 0 !important;
    overscroll-behavior: contain;
  }

  @media (max-width: 768px) {
    height: max-content;
  }

  @media (max-width: 600px) {
    padding: 15px;
  }

  .otherTrainings {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    width: 100%;

    &.mute {
      height: 0 !important;
      display: none;
    }

    .training_1, .training_2 {
      position: relative;

      display: flex;
      align-items: center;

      width: 45%;
      margin-bottom: 150px;

      text-align: center;

      img {
        width: 70%;
        margin: 0 auto;
      }

      p:hover {
        color: white !important;
        cursor: pointer;
        transform: scale(1.1);
      }

      @media (max-width: 1400px) {
        width: 50%;
        margin: 10% 15%;
      }

      @media (max-width: 1024px) {
        width: 80%;
        margin: 10% 15%;
      }

      @media (max-width: 425px) {
        width: 90%;
        margin: 10% 12%;
        p {
          font-size: x-small !important;
        }
      }
    }

    .training_1 {
      margin-top: 70px;
    }
  }
}