.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;

  visibility: hidden;
  opacity: 0;
  z-index: 99;

  background: rgba(0, 0, 0, 0.7);
  transition: opacity 200ms;

  .popup {
    position: relative;
    width: 80%;
    height: max-content;


    margin: 5vh auto;
    padding: 20px;

    background: #fff;
    border-radius: 5px;
    transition: all 0.5s ease-in-out;

    @media (max-width: 768px) {
      width: 100%;
      padding: 15px;
      margin: 0;
    }

    h2 {
      margin: 0 0 15px 0;

      color: #333;
    }

    .close {
      position: absolute;
      top: 20px;
      right: 30px;
      z-index: 10;

      transition: all 200ms;
      font-size: 30px;
      font-weight: bold;
      text-decoration: none;

      color: #35c6d5;
      filter: invert(100%);

      &:hover {
        cursor: pointer;

        // Color inverted
        color: #35c6d5;
        transform: scale(1.3);
      }
    }

    .content {
      //overflow-y: auto;
    }
  }
}


@media screen and (max-width: 700px) {
  .popup {
    width: 70%;
  }
}