.corporateTraining {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  margin: 50px 0;
  padding: 15px;

  border-radius: 20px;
  box-shadow: #a3b1c6d9 9px 9px 16px, #a3b1c638 -9px -9px 16px;
  color: dimgray;

  .topLine {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    .leftContent {
      width: 30%;

      @media (max-width: 768px) {
        width: 100%;
      }

      img {
        width: 100%;
        border-radius: 2%;
      }
    }

    .rightContent {
      flex: 1;
      margin: 0 15px;

      h1 {
        text-transform: uppercase;
        color: #ff8C21;
        font-size: 2rem;
      }
    }


  }

  .item {
    margin-right: 20px;
    width: 30%;

    @media (max-width: 1400px) {
      width: 100%;
    }

    h3 {
      color: #878787;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      margin: 15px 0;

      .circle {
        margin-right: 20px;

        .number {
          display: flex;
          align-items: center;
          justify-content: center;

          width: 6rem;
          height: 6rem;

          border-radius: 50%;
          border: 4px solid dimgray;

          color: #ff8C21;
          font-size: 3rem;
        }
      }
    }
  }
}