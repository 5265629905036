.aboutMe {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 50px 0;

  overflow: hidden;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }

  .leftContent {
    width: 65%;
    height: 30rem;

    overflow: auto;
    padding: 0 10px 0 0;

    white-space: pre-line;

    @media (max-width: 768px) {
      width: 100%;
      padding: 15px;
    }
  }

  .rightContent {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    overflow: hidden;

    @media (max-width: 768px) {
      width: 100%;
    }

    .aboutMeBg {
      height: 100%;
      width: 100%;
      object-fit: cover;
      position: relative;
    }

    .social {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;

      display: flex;
      justify-content: space-around;
      align-items: center;


      background: rgba(255, 255, 255, 0.5);
      height: 5rem;

      .socialLink {
        display: flex;
        align-items: center;
        padding: 10px;
        color: white;
        background: #ff962e;
        border-radius: 12px;
        box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);

        .faLogo {
          font-size: 30px;
          text-align: center;
        }
      }
    }
  }

  h1 {
    color: #ff8c21;
  }
}